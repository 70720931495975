import React from "react";
import { Layout, SEO } from "components/common";
import { makeStyles } from "@material-ui/core/styles";
import {
  Document,
  Intro,
  AboutCompany,
  Skills,
  Contact,
  Advantages,
  MovementMap,
  Questions,
  Calculator,
  Equipment,
  Clients,
  Phones,
  Modal,
  Video,
} from "components/landing";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "0 0.78vw",
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <Layout>
      <SEO />
      <div className={classes.wrapper}>
        <Intro />
        <AboutCompany />
        <Advantages />
        <MovementMap />
        <Equipment />
        <Calculator />
        <Video />
        <Questions />
        <Clients />
        {/* <Phones /> */}
        <Contact />
        <Document />
      </div>
    </Layout>
  );
};
